import '@concretecms/bedrock/assets/bedrock/js/frontend'; 
// import '@concretecms/bedrock/assets/navigation/js/frontend';
import '../../../../../../vendor/triple-i/js-plugins/auto-height';
import '../../../../../../vendor/triple-i/js-plugins/data-url-handler';
import './lib/parallax-image'

import './accordion-autoheight';
import './parallax-container';
import './nav-scroll';
import './simple-select';
import './top-navigation-bar';
import '../../../../../packages/workshop-management/single_pages/account/workshops/view.src';